.react-paginate-main{
    list-style: none;
    margin: 1rem 0 4rem 0;
    a{
        text-decoration: none;
        color: black;
        font-size: 0.75rem;
        padding: 0;
    }
    ul{
        @media screen and (max-width: 768px){
           padding-left: 0;
        }
    }
    li{
    padding: 0.4rem 0;
    @media screen and (max-width: 768px){
        display: flex;
        align-items: center;
        justify-content: center;
    }
    cursor: pointer;
      text-align: center;
      min-width: 2rem;
        border-radius: 50%;
        font-size: 0.75rem;
        background-color: $grey-B3B;
    }
    .selected{
        background-color: $blue-211 !important;
    }
    li:first-child, li:last-child{
        background-color: transparent;
    }
}