.custom-date-picker{
    border-color: $grey-D5D;
   
}

.date-group-range-container{
    gap: 0.5rem;
    @media screen and (max-width: 768px){
        width: 100%;      
    }
}

.clear-date-icon{
    position: absolute;
    top: 25%;
    right: 25%;
    cursor: pointer;
}