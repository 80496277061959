.admin-interface-container{  
    gap: 1.25rem;
    max-height: 100vh;
    overflow-x: hidden !important;
    height: 100vh;
    .admin-menu{
        min-width: 15rem;
        width: 15rem;
        padding: 5rem 1.5rem;
        .menu-routes{
            gap: 1.5rem;
            .a{
                gap: 0.75rem;
                padding: 0.5rem 1.5rem 0.5rem 1rem;
            }
        }
    }
    .admin-main{
        width: calc(100% - 15rem - 1.5rem) !important;
        overflow: hidden;
        .main-head{
            padding-right: 1.8rem;
            padding-top: 5rem;
            padding-bottom: 1.65rem;
            p{
                margin: 0;
            }
            .flex{
                gap: 0.5rem;
            }
            .head-name{
                letter-spacing: -0.00713rem;
                font-size: 2rem !important;
            }
        }
    }
}

.action-button{
    padding: 9px 12px !important;
}

.mobile-admin-interface-container{
width: 100vw;
overflow: hidden;
    .a,a{
        gap: 0.75rem;
        padding: 0.5rem 1.5rem 0.5rem 1rem;
    }
    .admin-icon{
        width: 2rem;
    }
   
}