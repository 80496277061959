@include font-face(Montserrat-Regular, "../../fonts/Montserrat/Montserrat-Regular", 400, normal, ttf);
@include font-face(Montserrat-Medium, "../../fonts/Montserrat/Montserrat-Medium", 500, normal, ttf);
@include font-face(Montserrat-SemiBold, "../../fonts/Montserrat/Montserrat-SemiBold", 600, normal, ttf);
@include font-face(Montserrat-Bold, "../../fonts/Montserrat/Montserrat-Bold", 700, normal, ttf);


@include font-face(NunitoSana-Regular,"../../fonts/NunitoSans/NunitoSans_10pt-Regular",400,normal,ttf);
@include font-face(NunitoSana-Bold,"../../fonts/NunitoSans/NunitoSans_10pt-Bold",700,normal,ttf);

.montserrat-regular {
    font-family: 'Montserrat-Regular', sans-serif;
}
.montserrat-medium {
    font-family: 'Montserrat-Medium', sans-serif;
}
.montserrat-semibold {
    font-family: 'Montserrat-SemiBold', sans-serif;
}
.montserrat-bold {
    font-family: 'Montserrat-Bold', sans-serif;
}


.nunitoSans-regular {
    font-family: 'NunitoSana-Regular', sans-serif;
}
.nunitoSans-bold {
    font-family: 'NunitoSana-Bold', sans-serif;
}


p,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-size: unset ;
}