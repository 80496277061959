.services-main-container{
   
}

.services-edit{
    padding: 0rem 2.5rem;
    @media screen and (max-width: 768px){
        padding: 0rem 1.5rem;
        width: 100% !important;
        
    }
    .edit-heading{
        font-size: 14px !important;
        font-weight: 600;
    }
    .edit-main{
    //  padding: 0rem 4.5rem;
    }
 }