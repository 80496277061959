
// color variables
$blue-221 :#211551;
$black-202:#202224;
$black:black;
$white:white;
$red-CE2:#CE2D2D;
$grey-F9F:#F9F9F9;
$grey-D8D:#D8D8D8;
$grey-EEE:#EEEEEE;
$grey-808:#808080;
$green-00B:#00B69B;
$red-EF3:#EF3826;
$purple-622:#6226EF;
$purple-E3D:#E3DCFF;
$grey-B3B:#B3B3B3;
$red-FF5:#FF5E5E;
$grey-D5D:#D5D5D5;
$blue-211:#211551;
$grey-F4F: #F4F4F4;
$blue-622:#6226EF;
$red-EF3:#EF3826;
$blue-622-rgba:rgba(98, 38, 239, 0.2);
$green-00B-rgba:rgba(0, 182, 155, 0.2);
$red-EF3-rgba:rgba(239, 56, 38, 0.2);


$standard-width: 144rem