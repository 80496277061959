.input{
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.625rem;
    font-size: 0.9375rem;
    font-family: Montserrat-Medium, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 500;
    line-height: 1;
    border: none;
    outline: none;
    background-color: $grey-EEE;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input:focus{
   border: none;
   overflow: none;
}

.slot-picker-wrapper {
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: $grey-EEE transparent; /* For Firefox: thumb color, track color */
    overflow-y: auto; /* Only vertical scroll */
    overflow-x: hidden; /* Hide horizontal scroll */
    @media screen and (max-width: 768px){
        max-width: 100% !important;
        
    }}

/* WebKit Browsers (Chrome, Safari) */
.slot-picker-wrapper::-webkit-scrollbar {
    width: 0.5rem; /* Width of the scrollbar */
    height: 0; /* No horizontal scrollbar */
}

.slot-picker-wrapper::-webkit-scrollbar-track {
    background: transparent; /* Make the track invisible */
}

.slot-picker-wrapper::-webkit-scrollbar-thumb {
    background-color: $grey-EEE; /* Thumb color */
    border-radius: 0.25rem; /* Rounded corners on the thumb */
}

/* Change thumb color on hover */
.slot-picker-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: $grey-EEE; /* Same color as default */
}

/* Change thumb color on active (clicking) */
.slot-picker-wrapper::-webkit-scrollbar-thumb:active {
    background-color: $grey-EEE; /* Same color as default */
}

/* When window is inactive */
.slot-picker-wrapper::-webkit-scrollbar-thumb:window-inactive {
    background-color: $grey-EEE; /* Same color as default */
}

.date-range-reset{
    min-width: fit-content !important;
}

.filter-button{
    box-shadow: none !important;
    border: 1px solid $grey-B3B !important;
    height: 56px !important;
    @media screen and (max-width: 768px){
        width: 100% !important;
        
    }
}
.offcanvas{
    width: 100% !important;
}