.bookings-main-container{
   
}
.text-area-input{
    width: 80%;
    height: 8rem;
    outline: none;
    border: 1.25px solid $grey-F4F;
    padding: 0.75rem 1rem;
}
.text-area-input:focus{
outline: none;
border: none;
}

textarea{
    resize: none;
}
.date-slot{
    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}