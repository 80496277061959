.custom-table-container{
    .overflow-scroll{
        padding-bottom: 4rem;
    }
    border-radius: 0.5rem;
    margin-bottom: 0 !important;
    tr{
        td{
        padding: 1.25rem 2rem;
        min-width: 5rem;
        font-size: 0.75rem;
        max-width: fit-content;
        white-space: nowrap;
        font-family: Montserrat-Semibold, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        opacity: 0.9;
        }
        th{
        font-size: 0.75rem;
        padding: 1rem 2rem;
            min-width: 5rem;
        }
        
    }  
}

.table-main-container{
    width: 99%;
    border-radius: 0.5rem;
    scrollbar-width: thin;
    scrollbar-color: $black-202;
    scroll-margin: 3rem;

        ::-webkit-scrollbar {
            width: 0;
            height: 6px;
            display: block;
          }
          
          ::-webkit-scrollbar-track {
            background: #D9D9D9;
            border: none;
            outline: none;
          }
          
          ::-webkit-scrollbar-thumb {
            background-color: $black-202;
            border-radius: 10px;
          }
}
