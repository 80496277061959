.dashboard-main-container{
    .card-container{
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        .card-main{
            width: calc(50% - 2rem);
            @media (max-width: 768px){
                width: 90%;
            }
            padding: 1.5rem;
            text-align: center;
            cursor: pointer;
            min-height: 7.25rem;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }
    }
}