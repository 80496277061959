@import "../abstracts/variables";

$colors: (
  blue-221: $blue-221,
  grey-EEE: $grey-EEE,
  black-202: $black-202,
  black: $black,
  white: $white,
  red-CE2: $red-CE2,
  red-EF3: $red-EF3,
  grey-F9F: $grey-F9F,
  grey-D8D: $grey-D8D,
  green-00B: $green-00B,
  purple-622: $purple-622,
  purple-E3D: $purple-E3D,
  grey-808: $grey-808,
  grey-B3B: $grey-B3B,
  red-FF5: $red-FF5,
  grey-D5D: $grey-D5D,
  blue-211: $blue-211,
  blue-622: $blue-622,
  grey-F4F:$grey-F4F,
  transparent: rgba(black,0),
  blue-622-rgba:$blue-622-rgba,
  green-00B-rgba: $green-00B-rgba,
  red-EF3-rgba: $red-EF3-rgba
  
);

// Generate classes for each color
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .#{$name} {
    color: $color;
  }

  .border-#{$name} {
    border-color: $color;
  }
}
