@import "../abstracts/mixins";
@import "../abstracts/variables";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
input:focus, fieldset:focus, textarea:focus, select:focus{
    outline: none !important;
}

.border{
    border-width: 1px;
    border-style: solid;
}
.pointer{
    cursor: pointer;
}
.cursor-not-allowed{
    cursor:not-allowed;
}
.gap-sm{
    gap: 0.5rem;
}
.gap-md{
    gap: 1rem;
}
.gap-lg{
    gap: 1.5rem;
}
.gap-xl{
    gap: 2rem;
}
a,.a{
    font-style: normal;
    text-decoration: none;
    color: $black;
}
::-webkit-scrollbar{
    display: none;
    width: 0;
}

.rounded-sm{
    border-radius: 0.25rem;
}
.rounded-md{
    border-radius: 0.5rem;
}
.rounded-lg{
    border-radius: 1rem;
}
.rounded-xl{
    border-radius: 2rem;
}
.rounded{
    border-radius: 9999px;
}


    @for $i from 0 through 100 {
      .w-#{$i} {
        width: #{$i}rem ;    
      }
      .h-#{$i} {
        height: #{$i}rem;
      }
      .m-#{$i} {
       margin: #{$i}rem;
      }
      .p-#{$i} {
        padding: #{$i}rem;
      }
      .round-#{$i} {
        border-radius: #{$i}px;
      }
    }

    .w-full{
        width: 100%;
    }
    .h-full{
        height: 100%;
    }
    .w-fit{
        width: fit-content;
    }
    .m-auto{
        margin: auto;
    }
    .p-auto{
        padding: auto;
    }
    .w-screen{
        width: 100vw;
    }
    .h-screen{
        height: 100vh;
    }

    @for $i from 1 through 100 {
        .f-s-#{$i} {
            font-size: #{$i}px;
        }
    }
   
      
    .container-standard{
        max-width: $standard-width;
        width: 100%;
        margin: 0 auto;
    }
    .overflow-scroll{
        overflow: auto;
    }
    .overflow-hidden{
        overflow: hidden;
    }
    .overflow-ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
  .relative{
        position: relative;
  }
  


  

// Flex classes
.flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-space-between {
    justify-content: space-between;
}   
.justify-space-around {
    justify-content: space-around;
}
.align-start {
    align-items: flex-start;
}
.align-end {
    align-items: flex-end;
}
.align-center {
    align-items: center;
}
.align-baseline {
    align-items: baseline;
}
.underline{
    text-decoration: underline;
}
.align-stretch {
    align-items: stretch;
}
.align-content-start {
    align-content: flex-start;
}
.align-content-end {
    align-content: flex-end;
}
.align-content-center {
    align-content: center;
}
.align-content-space-between {
    align-content: space-between;
}
.align-content-space-around {
    align-content: space-around;
}
.align-content-stretch {
    align-content: stretch;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.self-baseline {
    align-self: baseline;
}
.self-stretch {
    align-self: stretch;
}

   

.modal-main{
    @media screen and (max-width: 768px){
        max-width: calc(100% - 28px)  !important;
        padding: 2rem 1rem !important;
        width: unset !important;
    }
}

button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: $black;
    &:focus {
        outline: none;
    }
}