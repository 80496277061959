.home-container{
    .home-left,.home-right{
        width: 50%;
    }
    .home-right{
        height: 100%;
        .login-container{
            flex-direction: column;
            gap: 2rem;
         
            .login-main{
                padding: 2.5rem 3rem;
                border-radius: 0.25rem;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                width: 30rem;
                .login-input{
                    .label{
                        margin: 0.5rem;
                    }
                    .input{
                        width: 100%;
                        padding: 0.75rem 1rem;
                        border-radius: 0.65rem;
                        background: #EEE;
                        border: 1.5px solid rgba(127, 140, 141, 0.00);
                    }
                    .input:focus{
                       outline: none;
                    }
                }
                .login-button button{
                    width: 100%;
                    background-color: $blue-221;
                    color: $white;
                    margin: 1.5rem 0;
                    padding: 0.75rem 1.5rem;
                    border-radius: 0.65rem;
                }
            }
            height: 100%;
        }
    }
}
.mobile-img-interface{
    width: 8rem;
}

.mobile-home-container{
    display: flex;
    flex-direction: column;
    .home-left{
        height: 40vh;
        img{
            width: 14rem;
        }
    }
    .home-right{
        height: unset;
        .login-container{
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 3rem;
            width: 100%;     
            padding: 0 1rem;    
            .login-main{
                padding: 2.5rem 1rem;
                width: 100%;
                border-radius: 0.25rem;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                .login-input{
                    .label{
                        margin: 0.5rem;
                    }
                    .input{
                        width: 100%;
                        padding: 0.75rem 1rem;
                        border-radius: 0.65rem;
                        background: #EEE;
                        border: 1.5px solid rgba(127, 140, 141, 0.00);
                    }
                    .input:focus{
                       outline: none;
                    }
                }
                .login-button button{
                    width: 100%;
                    background-color: $blue-221;
                    color: $white;
                    margin: 1.5rem 0;
                    padding: 0.75rem 1.5rem;
                    border-radius: 0.65rem;
                }
            }
            height: 100%;
        }
    }
}